import { initCarouselSwiper } from '../swiper/swiper-carousel';
import { initModal } from '../helpers/modal';

const toplistItems = document.getElementById('bonus-toplist-items');
const toplistModals = document.getElementById('bonus-toplist-modals');
const bonusToplistFilters = document.getElementsByClassName('js-bonus-toplist-filter');
const bonusToplistLink = document.getElementById('bonus-toplist-link');

function httpGetAsync(pageType = '') {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
            const { partnersCarousel, partnersModals } = JSON.parse(xmlHttp.responseText);

            toplistItems.innerHTML = partnersCarousel;
            toplistModals.innerHTML = partnersModals;

            initCarouselSwiper();
            initModal();
        }
    };

    xmlHttp.open('GET', `/toplist/bonus-carousel-fetch/?pageType=${pageType}`, true); // true for asynchronous
    xmlHttp.send(null);
}

httpGetAsync();
bonusToplistFilters[0].classList.add('active');

for (let i = 0; i < bonusToplistFilters.length; i += 1) {
    bonusToplistFilters[i].onclick = function () {
        httpGetAsync(this.dataset.pageType);
        bonusToplistLink.innerHTML = `View ${this.innerHTML.toLowerCase()} bonuses`;
        bonusToplistLink.href = this.dataset.pageUrl;

        Array.from(bonusToplistFilters).forEach((el) => el.classList.remove('active'));
        this.classList.add('active');
    };
}